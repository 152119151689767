<template>
  <div class="cardDriver">
    <div class="cardDriver-content">
      <div class="btnClose" @click="sendEvent()">
        <CIcon name="cil-x" height="25" />
      </div>

      <div class="cardDriver-title">{{ title }}</div>
      <hr />

      <div v-if="notification">
        <CAlert :color="notificationType">
          {{ notification }}
        </CAlert>
      </div>

      <div
        class="cardDriver-subtitle btn-plus"
        @click="collapseBottom = !collapseBottom"
      >
      {{$t('driver_details')}}
        <CIcon name="cil-plus" />
      </div>
      <CCollapse :show="showCollapseBottom == collapseBottom">
        <BlockInfo
          :title="$t('first_name')"
          v-model="_driver.firstName"
          :disabled="inputsDisabled"
        />
        <BlockInfo
          :title="$t('last_name')"
          v-model="_driver.lastName"
          :disabled="inputsDisabled"
        />
        <BlockInfo
          :title="$t('username')"
          v-model="_driver.username"
          :disabled="inputsDisabled"
        />
        <BlockInfo
          :title="$t('email')"
          v-model="_driver.email"
          :disabled="inputsDisabled"
        />
        <BlockInfo :title="$t('license')" v-model="_driver.license" :disabled="true" />
        <BlockInfo
          :title="$t('phone')"
          v-model="_driver.phoneNumber"
          :disabled="inputsDisabled"
        />
        <BlockInfo title="SAP Id" v-model="_driver.sapId" :disabled="true" />
        <BlockInfo
          :title="$t('sap_driver_name')"
          v-model="_driver.rcsUserName"
          :disabled="true"
        />
        <BlockInfo
          :title="$t('mobi_user_number')"
          v-model="_driver.mobiUserNo"
          :disabled="true"
        />
        <BlockInfo
          :title="$t('id_device')"
          v-model="_driver.idDevice"
          :disabled="true"
        />
        <BlockInfo
        :title="$t('name_device')"
          v-model="_driver.nameDevice"
          :disabled="true"
        />
        <BlockInfo
        :title="$t('os_version')"
          v-model="_driver.osVersion"
          :disabled="true"
        />
        <BlockInfo :title="$t('version')" v-model="_driver.version" :disabled="true" />
        <BlockInfo
          :title="$t('products')"
          v-model="_driver.products"
          :disabled="true"
        />
        <div v-if="_driver.products.length == 0">
          <CAlert :color="'warning'">
            {{$t('select_least_product')}}.
          </CAlert>
        </div>
        <div class="cardDriver-sectionButtons">
          <CButton
            color="white"
            size="lg"
            class="cardDriver-button"
            @click="sendEvent()"
            >{{$t('cancel')}}</CButton
          >
    <CButton
            :color="'success'"
            size="lg"
            class="cardDriver-button"
            @click="actionEvent()"
            >{{$t('reset_password')}}</CButton
          >
        </div>
      </CCollapse>
    </div>
    <div class="cardDriverShadow" @click="sendEvent()"></div>
  </div>
</template>

<script>
import BlockInfo from "./BlockInfo";
import BlockInfoSelect from "./BlockInfoSelect";

export default {
  name: "CardDriver",
  components: {
    BlockInfo,
    BlockInfoSelect,
  },
  props: {
    driver: Object,
    usersRCS: Array,
    title: String,
    notification: String,
    notificationType: String,
    btnEventText: String,
    btnEventType: String,
    inputsDisabled: Boolean,
    countryCodes: Array,

    showCollapseTop: Boolean,
    showCollapseProducts: Boolean,
    showCollapseBottom: Boolean,
  },
  computed: {
    _driver() {
      return this.driver;
    },
    _usersRCS() {
      return this.usersRCS;
    },
    countries() {
      if (this.countryCodes.length > 1) return this.countryCodes.slice(1);
      //["PL"]
      else {
        return this.countryCodes;
      }
    },
  },
  data() {
    return {
      collapseTop: true,
      collapseProducts: false,
      collapseBottom: true,
      availableProducts: ["concrete", "cement", "aggregates"],
      selectedProducts: [],
      formSearch: {
        name: "",
        country: "",
        license: "",
      },
      userRCS_selected: null,
    };
  },
  created() {
    
  },
  methods: {
    sendEvent() {
      (this.classHidden = "hidden"), this.$emit("close-card-driver", false);
    },
    actionEvent() {

        this.$emit("reset-password-user", this._driver.username);
     
    },
  
  },
};
</script>

<style scoped>
.cardDriverShadow {
  width: 100%;
  height: 100vh;
  background: #000015;
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10100;
}
.cardDriver {
  width: 100%;
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000;
}
.cardDriver-content {
  position: absolute;
  right: 0;
  top: 0;
  width: 90%;
  height: 100vh;
  max-width: 800px;
  background: #fff;
  box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.25);
  z-index: 10200;
  padding-top: 53.78px;
  padding-left: 3em;
  padding-right: 3em;

  overflow-y: auto;
}
.btnClose {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1.5em;
  cursor: pointer;
}

.cardDriver-title {
  font-size: 20px;
  margin-bottom: 1em;
}
.cardDriver-subtitle {
  width: 100%;
  font-size: 16px;
  /* margin-top: 1em;
  margin-bottom: 1em; */
  padding: 1em;
  line-height: 1.3;
}
.btn-plus {
  margin-top: 1em;
  margin-bottom: 1em;
  cursor: pointer;
  transition: all 0.3s;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}
.btn-plus:hover {
  background: rgba(0, 0, 0, 0.05);
}

.cardDriver-usersRCS {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.cardDriver-inputGroup {
  width: 25%;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.cardDriver-sectionButtons {
  padding: 1em;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.cardDriver-button {
  margin-left: 1em;
  font-size: 14px;
}

.product-selector label,
.product-selector input {
  cursor: pointer;
  font-size: 16px;
}
.product-selector input {
  position: relative;
  margin-right: 6px;
}
</style>